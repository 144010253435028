import { useMiniCartContext } from '@bluheadless/ui-logic/src/providers/minicart'
import { useSearchContext } from '@bluheadless/ui-logic/src/providers/search'
import { useMiniWishListContext } from '@bluheadless/ui-logic/src/providers/mini-wish-list'
import { Stack as MuiStack } from '@mui/material'
import { cx } from '@emotion/css'
import { number, oneOf, shape, string } from 'prop-types'
import MultiDrawerMenu from '../../molecules/menu/multi-drawer-menu'
import MiniCartTrigger from '../../molecules/minicart'
import NotifyMessage from '../../molecules/notify-message'
import SearchTrigger from '../../molecules/search'
import SidebarNavigation from '../sidebar-navigation'
import { GridContainer, GridItemCenter, GridItem, Header, LogoWrapper } from './header-mini-full.styled'
import MiniWishListTrigger from '../../molecules/mini-wish-list'
import Logo from './logo'

const HeaderFullMini = ({
	className,
	logoProps,
	look,
	multiDrawerMenuProps,
	notifyMessageProps: { message, position, ...notifyMessageProps },
}) => {
	const { setOpen: setOpenSearch } = useSearchContext()
	const { setOpen: setOpenMiniCart } = useMiniCartContext()
	const { setOpen: setOpenMiniWishList } = useMiniWishListContext()

	return (
		<Header className={cx('HeaderFullMini-root', className)} message={message} position={position}>
			{message && position === 'top' && <NotifyMessage message={message} {...notifyMessageProps} />}

			<GridContainer alignItems="center" container>
				<GridItem item xs={3}>
					<MuiStack alignItems="center" direction="row" spacing={2}>
						<SidebarNavigation
							anchor="left"
							hamburgerButtonProps={{
								className: 'mini-trigger',
							}}
							multiDrawerMenuProps={multiDrawerMenuProps}
						/>

						<SearchTrigger
							className="mini-trigger"
							label={null}
							onClick={() => {
								setOpenSearch(true)
							}}
						/>
					</MuiStack>
				</GridItem>

				<GridItemCenter item xs={6}>
					<LogoWrapper look={look}>
						<Logo {...logoProps} />
					</LogoWrapper>
				</GridItemCenter>

				<GridItem alignItems="center" item xs={3}>
					<MuiStack alignItems="center" direction="row" justifyContent="flex-end" spacing={2}>
						<MiniWishListTrigger
							className="mini-trigger"
							iconProps={{
								fontSize: 'medium',
							}}
							onClick={() => {
								setOpenMiniWishList(true)
							}}
						/>

						<MiniCartTrigger
							className="mini-trigger"
							onClick={() => {
								setOpenMiniCart(true)
							}}
						/>
					</MuiStack>
				</GridItem>
			</GridContainer>

			{message && position === 'bottom' && <NotifyMessage message={message} {...notifyMessageProps} />}
		</Header>
	)
}

HeaderFullMini.defaultProps = {
	notifyMessageProps: {
		position: 'bottom',
	},
}

HeaderFullMini.propTypes = {
	/**
	 * Logo props
	 */
	logoProps: shape({
		/**
		 * Original height
		 */
		originalHeight: number,
		/**
		 * Original width
		 */
		originalWidth: number,
		/**
		 * Source
		 */
		src: string.isRequired,
	}),
	/**
	 * Multi-drawer menu props
	 */
	multiDrawerMenuProps: shape(MultiDrawerMenu.propTypes),
	/**
	 * Notify message position
	 */
	notifyMessagePosition: oneOf(['top', 'bottom']),
	/**
	 * Notify message props
	 */
	notifyMessageProps: shape({ ...NotifyMessage.propTypes, position: string }),
}

export default HeaderFullMini
